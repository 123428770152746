import cx from 'classnames'
import dynamic from 'next/dynamic'

import { type SanityHeroModule } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'
import ComplexPortableText from '@components/complex-portable-text'

const LottieAnimation = dynamic(() => import('@components/lottie-animation'))
const MuxVideo = dynamic(() => import('@components/video/mux-video'))

type HeroProps = Pick<
  SanityHeroModule,
  'content' | 'bgType' | 'photos' | 'lottieAssetUrl' | 'muxVideo'
> & {
  overlay?: boolean
}

const getRatio = (ratio?: number, defaultRatio?: number) => {
  if (typeof ratio === 'undefined' || ratio === 0) {
    return defaultRatio ?? 1
  }

  return ratio
}

const Hero = ({
  content,
  bgType,
  photos,
  lottieAssetUrl,
  muxVideo,
  overlay,
}: HeroProps) => {
  return (
    <section
      className={cx(
        'relative overflow-hidden bg-pageBG max-h-[calc(100vh-var(--headerHeight))] md:max-h-[calc(75vh-var(--headerHeight))]',
        {
          'before:absolute before:inset-0 before:z-[1] before:bg-pageText before:bg-opacity-30':
            overlay,
        }
      )}
    >
      <div className="container absolute inset-0 z-10 flex flex-col justify-end">
        {!!content && (
          <ComplexPortableText
            content={content}
            className="rc rc-hero text-pageBG my-8 sm:my-12 md:my-20"
          />
        )}
      </div>

      {bgType === 'photo' && !!photos && (
        <>
          {photos.desktopPhoto && (
            <Photo
              image={photos.desktopPhoto}
              fill
              priority
              className="relative w-full z-0 h-full hidden sm:block"
              style={{
                paddingTop: `${
                  100 / getRatio(photos.desktopPhoto.customRatio, 16 / 9)
                }%`,
              }}
              imageClassName="object-cover"
            />
          )}
          {photos.mobilePhoto && (
            <Photo
              image={photos.mobilePhoto}
              fill
              priority
              className="relative w-full z-0 h-full sm:hidden"
              style={{
                paddingTop: `${
                  100 / getRatio(photos.mobilePhoto.customRatio, 5 / 7)
                }%`,
              }}
              imageClassName="object-cover"
            />
          )}
        </>
      )}

      {bgType === 'lottie-animation' && !!lottieAssetUrl && (
        <LottieAnimation assetUrl={lottieAssetUrl} />
      )}

      {bgType === 'mux-video' && !!muxVideo && (
        <MuxVideo
          video={muxVideo}
          showControls={false}
          autoplay
          muted
          loop
          customAspectRatio={{
            custom: true,
            desktop: '16:9',
            mobile: '5:7',
          }}
        />
      )}
    </section>
  )
}

export default Hero
